import { render, staticRenderFns } from "./ShyfsPage.vue?vue&type=template&id=203e2262"
import script from "./ShyfsPage.vue?vue&type=script&lang=js"
export * from "./ShyfsPage.vue?vue&type=script&lang=js"
import style0 from "./ShyfsPage.vue?vue&type=style&index=0&id=203e2262&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\KRHealth\\code\\develop\\kr-web-ctms\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('203e2262')) {
      api.createRecord('203e2262', component.options)
    } else {
      api.reload('203e2262', component.options)
    }
    module.hot.accept("./ShyfsPage.vue?vue&type=template&id=203e2262", function () {
      api.rerender('203e2262', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Consult/ConsultManager/ShyfsPage.vue"
export default component.exports