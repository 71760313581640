<template>
	<div class="shyfs-page">
		<div class="modal-header">
			<h2>{{ titleText }}</h2>
		</div>
		<Form
			style="padding: 0 20px; margin-top: 30vh"
			:model="shyfsFormData"
			ref="shyfsConsultForm"
			label-position="top"
			:rules="shyfsRules"
		>
			<FormItem label="请选择申请预约服务类型" prop="category">
				<Select
					v-model="shyfsFormData.category"
					@on-change="handleChangeCategory"
					placeholder="请选择申请预约服务类型"
				>
					<Option
						v-for="item in categoryList"
						:key="item.value"
						:value="item.value"
						>{{ item.label }}
					</Option>
				</Select>
			</FormItem>
		</Form>
		<div
			style="
				position: absolute;
				left: 0;
				right: 0;
				bottom: 30vh;
				text-align: center;
			"
		>
			<Button type="primary" @click="toNext">下一步 </Button>
		</div>
	</div>
</template>

<script>
import config from "@/config/options.config"

const { categoryList } = config
export default {
	name: "shyfsPage",
	data() {
		return {
			categoryList,
			titleText: "预约申请",
			shyfsFormData: {},
			shyfsRules: {
				category: [
					{
						required: true,
						message: "请选择申请预约服务类型",
						trigger: "change"
					}
				]
			}
		}
	},
	created() {},
	methods: {
		handleChangeCategory(v) {
			// const itemC = this.categoryList.find(item => item.value === this.shyfsFormData.category);
			// const numC = Number(this.shyfsFormData.category);
			// this.titleText = numC > 1 ? '预约申请' : (numC === 1 ? '检查申请' : itemC.label);
		},
		toNext() {
			this.$refs.shyfsConsultForm.validate(valid => {
				if (valid) {
					this.$router.push({
						name: "consultAdd",
						query: {
							category: this.shyfsFormData.category
						}
					})
				}
			})
		}
	}
}
</script>
<style lang="less">
body {
	margin: 0;
	width: 100%;
	min-width: unset;
	overflow-x: hidden;
	overflow-y: auto;
	box-sizing: border-box;
}

.shyfs-page {
	.modal-header {
		border-bottom: 1px solid #e8eaec;
		padding: 14px 16px;
		line-height: 1;
	}
}
</style>
